import React, { ReactElement, useEffect } from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { styleVariables } from "@lib/common";
import { useRouter } from "next/router";
import getConfig from "next/config";
import { ClientIdType } from "@module/user";
import { useTranslation } from "next-i18next";
import styled from "@xstyled/styled-components";

const StyledWelcomePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;
const StyledTitle = styled.div`
  font-size: 25px;
  font-weight: 500;
`;
const StyledLoading = styled.div`
  span {
    height: 25px;
    width: 25px;
    margin: 0 5px;
    background-color: ${styleVariables.colors.blue3};
    border-radius: 50%;
    display: inline-block;
    animation-name: dots;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    &:nth-child(2) {
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      animation-delay: 0.8s;
    }
  }
  @keyframes dots {
    50% {
      opacity: 0;
      transform: scale(0.7) translateY(10px);
    }
  }
`;
const Home = (): ReactElement => {
  // Define configs
  const router = useRouter();
  const { t } = useTranslation("auth");
  const { publicRuntimeConfig } = getConfig();

  useEffect(() => {
    router.push(
      `${publicRuntimeConfig.page.signIn.url}?clientId=${ClientIdType.teacher.value}`
    );
  }, []);

  return (
    <StyledWelcomePage>
      <StyledTitle>{t("welcomeToDigibricks")}</StyledTitle>
      <br />
      <StyledLoading>
        <span></span>
        <span></span>
        <span></span>
      </StyledLoading>
    </StyledWelcomePage>
  );
};

export const getStaticProps = async (context: any) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new Error("locale is missing");
  }
  return {
    props: {
      ...(await serverSideTranslations(locale, ["auth"])),
    },
  };
};

export default Home;
